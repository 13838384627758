@import
"../_utils.scss",
"../_mixins.scss",
"../_fonts.scss",
"../_colors.scss",
"../_variables.scss"

;
@import "~bootstrap/scss/bootstrap";

@import "layouts/header";
@import "layouts/banner";
@import "login/login";
@import "pages/pages";

body {
    a {
        color: $blue;
        &:hover {
            color: $blue;
        }
    }
    #banner{
        display: flex;
        align-items: center;
    }
    #main {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 135px);

        @include media-breakpoint-down(md) {
            height: initial;
        }

        > section {
            height: auto;
            flex: 1;
        }
        .btn {
            border-radius: 0;
            color: $white;
            letter-spacing: 1px;
            margin-bottom: 15px;
            &.btn-inversed {
                text-transform: uppercase;
                color: $blue;
                padding: 15px 80px;
                border: 1px solid $blue;
                font-size: 1.1rem;
                font-weight: 500;
                width: auto;
                transition: all .3s;
                &:hover {
                    background-color: $blue;
                    color: $white;
                }
            }
            &.btn-bordered {
                border: 2px solid $white;
                width: auto;
                font-size: 1.3rem;
                padding: 10px 20px;
                box-shadow:  0px 2px 10px black;
                text-shadow:  0px 1px 8px black;
                transition: all .3s;
                &:hover {
                    background-color: $white;
                    color: $grey;
                    text-shadow: none;
                }
            }
            &.btn-blue {
                font-size: 1.2rem;
                background-color: $blue;
                width: auto;
                box-shadow:  0px 2px 10px black;
                transition: all .3s;
                &:hover {
                    background-color: $blue-light;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    body {
        &.homepage {
            #main {
                height: 110vh;
            }
        }
        #main {
            .btn {
                &.btn-inversed {
                    font-size: 1rem;
                }
                &.btn-bordered {
                    font-size: 1rem;
                }
                &.btn-blue {
                    font-size: 1rem;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    body {
        &.homepage {
            #main {
                padding-top: 0;
                height: auto;

                .order-1, .order-2 {
                    height: 60vh;
                }

                #home {
                    .row {
                        align-items: stretch;
                    }
                }
            }
            #banner {
                position: initial;
            }
            #header {
                .navbar {
                    position: initial;
                }
            }
        }
        #main {
            .btn {
                &.btn-inversed {
                    font-size: .6rem;
                }
                &.btn-bordered {
                    padding: 10px;
                    font-size: .6rem;
                }
                &.btn-blue {
                    font-size: .6rem;
                }
            }
        }
    }
}
