@import
    "../../_fonts.scss",
    "../../_colors.scss",
    "../../_variables.scss"
;

body {
    #onRoad {
        .driver{
            margin-bottom: 1.3rem;
        }
        .info{
            font-size: 1.3rem;
        }
        .img-fluid{
            max-height: 250px;
        }
        .fit{
            width: fit-content;
        }
        .on-road-text{
            padding-top: 3rem;
            margin-bottom: 2rem;
        }
        .carInfo{
            font-size: 1.5rem;
        }
        .title{
            font-size: 1.3rem;
            width: 90%;
            text-align: center;
            border-bottom: 3px solid #000;
            line-height: 0.1em;
            margin: 10px 0 20px;
            span{
                background:#fff;
                padding:0 10px;
            }
        }
        .timer {
            width: 140px;
            height: 140px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin: 0 auto;
            .min {
                font-weight: 600;
                font-size: 2.5rem;
                line-height: 1.2;
                margin-top: -10px;
            }

            .unit {
                font-weight: 600;
                font-size: 1rem;
                text-transform: uppercase;
            }
        }
        .no-padding{
            .col-md-6{
                padding: 0;
            }
        }
    }

    .part-map {
        #map-wrapper {
            overflow: hidden;
            position: relative;
            height: 100%;
            width: 100%;

            #map {
                height: 100%;
                width: 100%;
            }
        }
    }
}


@media screen and (max-width: 768px) {
    body {
        .part-map {
            order: 1;
            height: 35% !important;
        }

        #onRoad {
            .timer-and-infos {
                margin-top: -3rem;
            }
            .img-fluid {
                display: none;
            }

            .part-info {
                order: 2;
                padding-top: 1rem !important;
                height: 65%;
            }

            .carInfo {
                font-size: 1rem;
            }

            .info {
                font-size: 1rem;
            }

            .title {
                font-size: 1rem;
                width: 90%;
                text-align: center;
                border-bottom: 3px solid #000;
                line-height: 0.1em;
                margin: 10px 0 20px;

                span {
                    background: #fff;
                    padding: 0 10px;
                }
            }

            .timer {
                width: 100px;
                height: 100px;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                margin: 0 auto;

                .min {
                    font-weight: 600;
                    font-size: 1.8rem;
                    line-height: 1.2;
                    margin-top: -10px;
                }

                .unit {
                    font-weight: 600;
                    font-size: 1em;
                    text-transform: uppercase;
                }
            }
        }
    }
}
