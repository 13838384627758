//@font-face {
//    font-family: 'ibm_plex';
//    src: url('/assets/fonts/ibmplexsans-medium-webfont.woff2') format('woff2'),
//         url('/assets/fonts/ibmplexsans-medium-webfont.woff') format('woff'),
//         url('/assets/fonts/ibmplexsans-medium-webfont.ttf') format('ttf');
//    font-weight: 500;
//    font-style: normal;
//
//}
//
//@font-face {
//    font-family: 'ibm_plex';
//    src: url('/assets/fonts/ibmplexsans-regular-webfont.woff2') format('woff2'),
//         url('/assets/fonts/ibmplexsans-regular-webfont.woff') format('woff');
//    font-weight: 400;
//    font-style: normal;
//
//}
//
//@font-face {
//    font-family: 'ibm_plex';
//    src: url('/assets/fonts/ibmplexsans-semibold-webfont.woff2') format('woff2'),
//         url('/assets/fonts/ibmplexsans-semibold-webfont.woff') format('woff'),
//         url('/assets/fonts/ibmplexsans-semibold-webfont.ttf') format('ttf');
//    font-weight: 600;
//    font-style: normal;
//
//}

.icon::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
