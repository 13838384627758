@import
"../../_fonts.scss",
"../../_colors.scss",
"../../_variables.scss"
;

body {
    #main {
        .about-container {
            height: calc(100vh - 135px);
            @include media-breakpoint-down(md) {
                height: initial;
            }
            #about {
                padding-top: 8vh;
                height: 80%;

                > .container {
                    max-width: 1650px;
                }

                .content-left {
                    @include media-breakpoint-down(sm) {
                        padding-bottom: 4vh;
                    }
                    .title {
                        color: $blue;
                        margin-bottom: 3vh;
                        font-size: 1.6rem;

                        @media (max-width: 1250px) {
                            font-size: 1.3rem;
                        }
                    }
                    .desc {
                        margin-bottom: 2vh;
                    }
                }
                .content-right {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    &-img {
                        flex: 1;
                        @include media-breakpoint-down(md) {
                            flex: 0;
                        }
                        img {
                            object-position: center;
                            object-fit: cover;
                            height: 100%;
                            width: 100%;
                        }
                    }
                    #map {
                        display: block;
                        margin: 0 auto;
                        height: 25vh;
                        width: 100%;
                    }
                }
            }
            #footer {
                flex: none;
                text-align: center;
                padding: 3vh 6vw;
                .home-link {
                    text-align: center;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    body {
        #main {
            .about-container {
                #about {
                    margin-top: 5vh;
                    padding: 0 6vw;
                    .content-left {
                        .title {
                            font-size: 1.3rem;
                            margin-bottom: 4vh;
                        }
                        .desc {
                            text-align: center;
                        }
                    }
                    .content-right {
                        //margin-top: 5vh;
                    }
                }
                #footer {
                    a {
                        display: block;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    body {
        #main {
            #about {
                .content-right {
                    img {
                        max-width: 100%;
                    }
                    #map {
                        display: block;
                        margin: 0 auto;
                        width: 100%;
                    }
                }
            }
        }
    }
}