#sms_form {
  .form-container {
    max-width: 800px;

    .question-label {
      font-size: 1.25rem;
    }

    .five-star-notation {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      height: 75px;
      padding: 0 10px;

      &:not(:checked) {
        & > input {
          position:absolute;
          opacity: 0;
          left: 25px;
          bottom: 0;
        }

        & > label {
          float:right;
          width:70px;
          overflow:hidden;
          white-space:nowrap;
          cursor:pointer;
          font-size:50px;
          color:#ccc;
          transition: all 0.25s;

          &:before {
            content: '★ ';
          }

          &:hover, &:hover ~ label {
            color: #deb217;
          }
        }
      }

      & > input:checked {
        & ~ label {
          color: #ffc700;

          &:hover,
          &:hover ~ label {
            color: #c59b08;
          }
        }

        & + label:hover,
        & + label:hover ~ label {
          color: #c59b08;
        }
      }

      & label:hover {
        & ~ input:checked ~ label {
          color: #c59b08;
        }
      }
    }
  }
}