#header {
    position: sticky;
    top: 0;
    z-index: 3;
    max-height: 150px;
    .navbar {
        height: 85px;
        padding: 1rem;
        @include media-breakpoint-down(sm) {
            height: 75px;
        }
        &-brand {
            > img{
                object-fit: contain;
                object-position: left center;
                height: 100% !important;
                width: 100% !important;
            }
        }
        &.bg-light {
            background-color: $white !important;
        }
        .nav-wrapper {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
            width: 100%;
            .navbar-toggler {
                border: 0;
                border-radius: 0;
                text-decoration: none;
                .icon-bar {
                    display: block;
                    width: 26px;
                    height: 2px;
                    border-radius: 2px;
                    background-color: $grey;
                    + .icon-bar {
                        margin-top: 7px;
                    }
                }
                &:hover,
                &:not(.collapsed) {
                    .icon-bar {
                        background-color: $blue;
                    }
                }
                &:focus {
                    outline: 0;
                }
            }
            #menu {
                position: absolute;
                right: -1rem;
                top: calc(100% + 1rem);
                width: calc(25% + .5rem);
                background-color: $blue;
                .navbar-nav {
                    .nav-item {
                        padding: 1em 0;
                        transition: all .3s;
                        &:hover {
                            background-color: $blue-light;
                        }
                        .nav-link {
                            font-size: 1.2rem;
                            color: $white;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

//@media (max-width: 1024px) {
//    #header {
//        .navbar {
//            .nav-wrapper {
//                #menu {
//                    .navbar-nav {
//                        padding: 2rem .5rem;
//                        .nav-item {
//                            + .nav-item {
//                                margin-top: 1rem;
//                            }
//                        }
//                    }
//                }
//            }
//        }
//    }
//}

@media (max-width: 767px) {
    #header {
        .navbar {
            .nav-wrapper {
                #menu {
                    width: calc(50% + .5rem);
                }
            }
        }
    }
}

@media (max-width: 576px) {
    #header {
        .navbar {
            padding: .5rem;
            .nav-wrapper {
                #menu {
                    right: -.5rem;
                    top: calc(100% + .5rem);
                }
            }
        }
    }
}
