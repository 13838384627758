// FLEX
@mixin flex($direction, $wrap, $justify-content, $align-items) {
    display: flex;
    flex-direction: $direction;
    flex-wrap: $wrap;
    justify-content: $justify-content;
    align-items: $align-items;
}

/*******************************************************************************************/

$grid-gutter-width: 30px;

@mixin clearfix {
	&:before,
	&:after {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}
}

@mixin add-breakpoint-grid($grid-size, $breakpoint, $class, $gutter: $grid-gutter-width) {
	$row-class: ".row";

	@if $grid-size != 12 {
		$row-class: unquote('".row-"#{$grid-size}');
	}

	#{$row-class} {
		@include clearfix;

		@for $i from 1 through $grid-size {
			> .col-#{$class}-#{$i} {
				position: relative;
				min-height: 1px;
				padding-left: $gutter / 2;
				padding-right: $gutter / 2;
			}
		}
	}

	@media (min-width: $breakpoint) {
		#{$row-class} {
			@for $i from 1 through $grid-size {
				> .col-#{$class}-#{$i} {
					float: left;
				}

				> .col-#{$class}-#{$i} {
					width: percentage($i / $grid-size);
				}

				> .col-#{$class}-offset-#{$i} {
					margin-left: percentage($i / $grid-size);
				}

				> .col-#{$class}-push-#{$i} {
					left: percentage($i / $grid-size);
				}

				> .col-#{$class}-pull-#{$i} {
					right: percentage($i / $grid-size);
				}
			}
		}
	}
}

@mixin custom-grid($size, $gutter: $grid-gutter-width) {
	.row-#{$size} {
		margin-left: -15px;
		margin-right: -15px;
		@include clearfix;

		@for $i from 1 through $size {
			> .col-xs-#{$i} {
				width: percentage($i / $size);
			}

			> .col-xs-push-#{$i} {
				left: percentage($i / $size);
			}

			> .col-xs-pull-#{$i} {
				right: percentage($i / $size);
			}

			> .col-xs-#{$i},
			> .col-sm-#{$i},
			> .col-md-#{$i},
			> .col-lg-#{$i} {
				position: relative;
				min-height: 1px;
				padding-left: $gutter / 2;
				padding-right: $gutter / 2;
			}
		}
	}

	@media (min-width: 768px) {
		.row-#{$size} {
			@for $i from 1 through $size {
				> .col-sm-#{$i} {
					float: left;
				}

				> .col-sm-#{$i} {
					width: percentage($i / $size);
				}

				> .col-sm-push-#{$i} {
					left: percentage($i / $size);
				}

				> .col-sm-pull-#{$i} {
					right: percentage($i / $size);
				}
			}
		}
	}

	@media (min-width: 992px) {
		.row-#{$size} {
			@for $i from 1 through $size {
				> .col-md-#{$i} {
					float: left;
				}

				> .col-md-#{$i} {
					width: percentage($i / $size);
				}

				> .col-md-push-#{$i} {
					left: percentage($i / $size);
				}

				> .col-md-pull-#{$i} {
					right: percentage($i / $size);
				}
			}
		}
	}

	@media (min-width: 1200px) {
		.row-#{$size} {
			@for $i from 1 through $size {
				> .col-lg-#{$i} {
					float: left;
				}

				> .col-lg-#{$i} {
					width: percentage($i / $size);
				}

				> .col-lg-push-#{$i} {
					left: percentage($i / $size);
				}

				> .col-lg-pull-#{$i} {
					right: percentage($i / $size);
				}
			}
		}
	}
}

/*******************************************************************************************/
// Custom mixins
/*******************************************************************************************/
