#banner {
    background-color: $grey;
    padding: .6rem 0;
    width: 100%;
    height: 50px;
    .title {
        color: $white;
        text-align: center;
        font-size: 1.3rem;
        letter-spacing: 1px;
    }
}

@media (max-width: 1024px) {
    #banner {
        padding: .4rem 0;
        .title {
            font-size: 1.1rem;
        }
    }
}

@media (max-width: 576px) {
    #banner {
        top: 60px;
        .title {
            font-size: .6rem;
        }
    }
}
