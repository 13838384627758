@import
"../../_fonts.scss",
"../../_colors.scss",
"../../_variables.scss"
;

body {

    #home {
        > .row {
            height: 100%;

            @include media-breakpoint-down(lg) {
                height: initial;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            width: 100%;
            background: $white url("/images/visuel_generic.jpg") no-repeat center center;
            background-size: cover;
            padding: 20px;

            &--container-left {
                display: flex;
                flex-direction: column;

                @include media-breakpoint-down(lg) {
                    min-height: calc(100vh - 135px);
                }

                @include media-breakpoint-down(sm) {
                    min-height: calc(100vh - 125px);
                }
            }

            &--sub-img {
                object-fit: contain;
                object-position: center;
                width: 100%;

                @include media-breakpoint-down(sm) {
                    display: none;
                }

                &-mobile {
                    display: none;
                    width: 100%;
                    @include media-breakpoint-down(sm) {
                        display: block;
                    }
                }
            }

            .row {
                &:first-child {
                    .desc {
                        font-size: 1.8rem;
                        color: $white;
                        text-shadow:  0px 1px 6px black;
                        padding-right: 6vw;
                        line-height: 1.4;
                        .blue {
                            font-weight: 500;
                            color: $blue;
                        }
                    }
                }
                &:last-child {
                    .footer {
                        text-align: center;
                        color: $white;
                        font-size: 1.3rem;
                        text-shadow:  0px 1px 6px black;
                    }
                }
            }
        }
        .right {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        #map {
            height: 100%;
            width: 100%;
        }
    }
}

@media (max-width: 1024px) {
    body {
        #home {
            .catch {
                font-size: 1.4rem;
                padding-right: 0;
            }
        }
    }
}

@media (max-width: 991px) {
    body {
        #home{

            //.left{
            //    height: 55%;
            //}
            //.right{
            //    height: 45%;
            //}

            .catch {
                min-height: 50%;
                line-height: 1.2;
                padding: 15px;
            }

            #map {
                min-height: 50%;
            }
        }
    }
}

@media (max-width: 767px) {
    body {
        #home {
            .catch {
                min-height: 60%;
                font-size: 1.5rem;
                padding-right: 0;
                line-height: 1.2;
            }
            #map {
                min-height: 40%;
            }
        }
    }
}
