.wrapperLogin {
    @include flex(column, nowrap, center, center);
    width: 100%;
    min-height: 100%;
    height: 100vh;
    color: #3b3b3b;

    &__title {
        @include font-size(18);
        font-weight: bold;
        color: #2A66EB;
        text-transform: uppercase;
    }

    #formContent {
        border-radius: 10px 10px 10px 10px;
        background: #fff;
        width: 90%;
        max-width: 450px;
        position: relative;
        text-align: center;
        padding: 2rem;
    }

    &-footer {
        text-align: center  ;
        width: 100%;
        display: block;
        border-top: 1px solid rgba(0,0,0,.1);
        border-bottom: 1px solid rgba(0,0,0,.1);
        padding: 2rem 1rem;
    }
}

