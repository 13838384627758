// Vars colors
$black : rgba(10,10,10, 1.0);
$white : rgba(255,255,255, 1.0);

// ------------------------
$blue : #2966ec;
$blue-light : #4b74ed;
$grey: #4d4d4d;
$lightgrey: rgba(0, 0, 0, 0.125);
$red: #FE141A;

//Some others colors
$twitter  :    rgba(0,172,237,1);
$facebook :    rgba(59,89,152,1);
$linkedin :    rgba(0,123,182,1);
$google-plus : rgba(223,81,56,1);
