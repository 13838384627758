@import
"../../_fonts.scss",
"../../_colors.scss",
"../../_variables.scss"
;

body {
  #main {
    #legals {
      padding: 8vh;
      .content {
        .title {
          color: $blue;
          margin-bottom: 3vh;
        }
        .desc {
          margin-bottom: 2vh;
        }
      }
    }
    #footer {
      flex: none;
      text-align: center;
      padding: 3vh 0;
      .home-link {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  body {
    #main {
      #legals {
        padding: 2vh;
      }
    }
  }
}