// --------------------------------------------------

// Utils

// --------------------------------------------------

.bold {
    font-weight: bold;
}
.italic {
    font-style: italic;
}
.mt15 {
    margin-top: 30px;
}
.mt30 {
    margin-top: 30px;
}
.mt40 {
    margin-top: 40px;
}
.fz08 {
    font-size: .8em;
}