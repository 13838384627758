@import
"../../_fonts.scss",
"../../_colors.scss",
"../../_variables.scss"
;

body {
    .custom-control-input{
        z-index: 2;
    }
    #main {
        #register {
            .legals{
                font-size: 12px;
                margin-top: 20px;
                margin-bottom: 20px;

                &.container {
                    margin-top: 60px;
                    margin-bottom: 20px;
                }
            }
            .page{
                &-content {
                    flex: 1;
                }
                &__title {
                    padding: 4vh 0;
                    font-size: 1.6rem;
                    font-weight: 600;
                }
            }
            .content-left {
                .flex-wrapper {
                    margin-bottom: 10px;
                    display: flex;
                    align-items: flex-start;
                    .col-form-label {
                        font-size: .95rem;
                    }
                    .normal {
                        flex: 0;
                        white-space: nowrap;
                        margin-right: 100px;
                        label {
                            display: block;
                            min-width: 70px;
                            text-align: left;
                            padding: 0;
                        }
                    }
                    .stretch {
                        display: flex;
                        flex: 1;
                        > input {
                            width: 100%;
                            border-radius: 0;
                            border: 1px solid $black;
                            padding: 2px 4px;
                        }
                        .custom-radio {
                            .custom-control-input:checked~.custom-control-label::before {
                                background-color: $grey;
                            }
                        }
                    }
                    &.has-error {
                        .stretch {
                            flex-direction: column;
                            input {
                                border-color: $red;
                            }
                            .help-block {
                                color: $red;
                                ul {
                                    list-style: none;
                                    margin: 0;
                                    padding: 0;
                                    li {
                                        font-size: .8rem;
                                    }
                                }
                            }
                        }
                    }
                }
                #address {
                    margin-top: 10px;
                }
                #map {
                    width: 100%;
                    height: 20vh;
                }
            }
            .content-right {
                display: flex;
                align-items: center;
                flex-direction: column;
                img {
                    display: block;
                    margin: 0 auto 6vh;
                    max-width: 550px;
                    width: 100%;
                }
                .desc {
                    font-size: 1.2rem;
                    line-height: 1;
                    margin-bottom: 4vh;
                }
                .btn {
                    box-shadow: none;
                    padding: 12px;
                    max-width: 550px;
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    body {
        #main {
            #register {
                .content-right {
                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    body {
        #main {
            #register {
                .content-left {
                    .flex-wrapper {
                        .normal {
                            margin-right: 40px;
                        }
                    }
                    #map {
                        height: 30vh;
                    }
                }
                .content-right {
                    margin-top: 5vh;
                }
            }
        }
    }
}